export default {
  "formT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formulaire d’inscription"])},
  "formP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certificat en Suivi-évaluation des projets et\nprogrammes de santé"])},
  "nom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom de famille"])},
  "prenom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénoms"])},
  "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
  "lieu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de naissance"])},
  "nationalite": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nationalité"])},
  "residence": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays de résidence"])},
  "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse email"])},
  "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Numéro de téléphone"])},
  "diplome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diplôme obtenu le plus élevé"])},
  "intitule": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Intitulé du diplôme le plus élevé"])},
  "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Décrivez brièvement vos expériences professionnelles pertinentes pour le programme (300 mots maximum)"])},
  "sendButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Soumettre ma candidature"])},
  "candidatureT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Candidature envoyée"])},
  "candidatureP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre candidature a été soumise avec succès. Nous vous contacterons pour confirmer votre pré-sélection."])},
  "button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Visitez notre site web"])},
  "buttonbase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Postuler au programme"])},
  "slogan": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certifiez-vous en Suivi-évaluation des projets et programmes de santé"])},
  "but1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Développez une expertise avérée en suivi-évaluation dans le secteur de la santé, avec notre programme à distance de certification professionnelle !"])},
  "objectifT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objectifs & Compétences visées "])},
  "objectifP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme de formation vous permet d’acquérir les compétences essentielles, pour mettre en place\net gérer des systèmes de suivi-évaluation au sein des projets et programmes de développement en\nsanté. Il vous prépare à ancrer les fonctions de suivi-évaluation dans les programmes de santé, mises\nen œuvre aussi bien par les organisations internationales que par les institutions publiques."])},
  "objectifT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Concepts généraux"])},
  "objectifP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les concepts et principes de base en suivi-évaluation."])},
  "objectifT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Normes éthiques"])},
  "objectifP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comprendre les normes éthiques du suivi-évaluation."])},
  "objectifT3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Systèmes de suivi"])},
  "objectifP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre en place et gérer un système efficace de suivi des projets en santé."])},
  "objectifT4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Méthodes d’évaluation"])},
  "objectifP4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utiliser les méthodes et outils appropriés pour l’évaluation d’un programme de santé."])},
  "objectifT5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalisation\ndes acquis"])},
  "objectifP5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyser les données et capitaliser les expériences ou connaissances acquises."])},
  "objectifT6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Communication"])},
  "objectifP6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produire un rapport d’évaluation et diffuser les résultats."])},
  "publicsT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Publics cibles & pré-requis"])},
  "publicsT1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Professionnels de la santé"])},
  "publicsP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Médecins ; chirurgiens ; infirmiers ; sages-femmes ;\npharmaciens ; paramédicaux ; étudiants en sciences de la santé."])},
  "publicsT2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Autres publics cibles"])},
  "publicsP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spécialistes du suivi et de l'évaluation ; coordonnateurs de projets ;\ngestionnaires de programmes ; évaluateurs indépendants ; personnel des bailleurs de fonds\nchargé d'évaluer les projets et les programmes ; personnels des organisations non\ngouvernementales ou humanitaires participant au suivi et à l'évaluation ; gestionnaires de\nprojets de santé…"])},
  "moyensT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Moyens pédagogiques"])},
  "moyensP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce programme est basé sur une méthode pédagogique d’approche par compétences et s’insère\nparfaitement dans votre agenda sans bousculer votre vie professionnelle ou personnelle."])},
  "formatT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])},
  "formatP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les cours sont dispensés à temps partiel, exclusivement à distance, en mode\nsynchrone (classes virtuelles) et asynchrone (cours pré-enregistrés)."])},
  "comiteT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comité"])},
  "comiteP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le comité pédagogique de ce programme est composé d’experts internationaux en\nsuivi-évaluation."])},
  "dureeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée"])},
  "dureeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["40 heures de cours."])},
  "periodeT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Période"])},
  "periodeP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du 1er juin au 30 juillet 2025"])},
  "evaluationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
  "evaluationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["À la fin du programme, l’apprenant(e) est soumis(e) à une épreuve de contrôle de\nconnaissance en ligne au format QCM."])},
  "certificationT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Certification"])},
  "certificationP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le programme est sanctionné par un certificat professionnel accrédité en\n« suivi-évaluation des projets et programmes de santé », qui est délivré aux apprenant(e)s\nayant obtenu une note supérieure ou égale à 60% au terme du contrôle des connaissances."])},
  "contenuT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contenu du Programme"])},
  "droitInscriptionT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Droit d’inscription"])},
  "droitInscriptionTN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["*Possibilité de paiements par tranches*"])},
  "droitInscriptionP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les droits d’inscription à la formation s’élèvent à"])},
  "droitInscriptionP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’inscription n’est considérée comme définitive qu’après règlement de ce montant auprès de\nl’organisme de formation médicale continue REMA, à condition que votre candidature soit acceptée.\nLa date limite pour s’inscrire au programme est le 30 octobre 2024. Places limitées !"])},
  "placeDisponible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les places disponibles étant limitées pour ce programme de certification, seules les candidatures\npertinentes seront retenues. Les candidats sélectionnés seront contactés par nos équipes afin de\nfinaliser leur inscription. Merci."])},
  "produitT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])},
  "renseignements": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements"])},
  "entrepriseT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entreprise"])},
  "entrepriseP1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
  "entrepriseP2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support"])},
  "entrepriseP3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Carrière"])},
  "right": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© 2022 REMA. Tous droits réservés."])},
  "confidentialites": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confidentialités"])},
  "conditions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conditions d’utilisation"])}
}